import {
  Button,
  Card,
  Checkbox,
  Col,
  ConfigProvider,
  Form,
  Image,
  Input,
  Radio,
  Row,
  Select,
  Typography,
  message,
} from "antd";
import React, { useEffect, useState } from "react";
import One from "./assets/1.png";
import Two from "./assets/2.png";
import Three from "./assets/3.png";
import WebFont from "webfontloader";
import Logo from "./assets/Logo.svg";

const { Text, Title } = Typography;
const { Option } = Select;
function App() {
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    WebFont.load({
      custom: {
        families: ["Madani Arabic"],
        urls: ["./font.css"], // replace with the path to your font file
      },
    });
  }, []);
  const onFinish = async (values) => {
    try {
      setLoading(true);
      // take the name and design from the form values
      // and add the name to the card design
      const { name, design } = values;
      // create a new image element
      let height = 380;
      let color = "#24465A";
      let source = One;
      if (design === "2") {
        color = "#FFFFFF";
        source = Two;
      } else if (design === "3") {
        color = "#D9C395";
        source = Three;
        height = 70;
      }

      const img = document.createElement("img");
      // set the source of the image to the selected design
      img.src = source;
      // wait for the image to load
      await new Promise((resolve) => {
        img.onload = resolve;
      });
      // create a new canvas element
      const canvas = document.createElement("canvas");
      // set the width and height of the canvas to the width and height of the image
      canvas.width = img.width;
      canvas.height = img.height;
      // get the 2d context of the canvas
      const ctx = canvas.getContext("2d");
      // draw the image on the canvas
      ctx.drawImage(img, 0, 0);
      // set the font size and font family, font familey is Madani Arabic;
      ctx.font = "80px Madani Arabic";
      // set the text alignment to the center
      ctx.textAlign = "center";
      // set the text baseline to the middle
      ctx.textBaseline = "middle";
      // set the fill style to white
      ctx.fillStyle = color;
      // set the stroke style to black
      ctx.strokeStyle = color;
      // set the line width to 3
      ctx.lineWidth = 3;
      // draw the stroke text on the canvas
      ctx.strokeText(name, canvas.width / 2, canvas.height - height);
      // draw the fill text on the canvas
      ctx.fillText(name, canvas.width / 2, canvas.height - height);
      // create a new anchor element
      const a = document.createElement("a");
      // set the download attribute of the anchor to "ramadan_card.png"
      a.download = "ramadan_card.png";
      // set the href attribute of the anchor to the data url of the canvas
      a.href = canvas.toDataURL();
      // click the anchor
      a.click();
      // remove the anchor
      a.remove();

      setLoading(false);
      message.success("Card generated successfully!");
    } catch (error) {
      console.log(error);
      setLoading(false);
      message.error("Something went wrong, please try again later!");
    }
  };
  return (
    <ConfigProvider theme={{ token: { colorPrimary: "#006658" } }}>
      <div
        style={{
          minHeight: "100vh",
          minWidth: "100vw",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#f0f2f5",
          flexDirection: "column",
        }}
      >
        <Image
          src={Logo}
          style={{
            width: 200,
            height: 100,
            marginBottom: 20,
          }}
          preview={false}
        />
        <Row
          style={{
            width: "100%",
          }}
          justify={"center"}
        >
          <Col
            xs={{ flex: "90%" }}
            sm={{ flex: "80%" }}
            md={{ flex: "60%" }}
            lg={{ flex: "45%" }}
            xl={{ flex: "45%" }}
          >
            <Card
              title={
                <Title
                  level={3}
                  style={{
                    textAlign: "center",
                  }}
                >
                  Ramadan Cards
                </Title>
              }
              bordered={false}
              style={{
                // minWidth: 300,
                // maxWidth: 500,
                width: "100%",
                // marginRight: 20,
                // marginLeft: 20,
                // add shadow;
                boxShadow: "0px 0px 20px 0px #00000030",
              }}
              // if mobile make max width 90%;
              // if desktop make max width 500px;
            >
              <Title level={5}>
                Create a Ramadan Card 🌙, by your name and share it with your
                beloved one ❤️
              </Title>
              <Form name='birthday_card_form' onFinish={onFinish}>
                <Form.Item
                  // make the form vertical;
                  labelCol={{ span: 24 }}
                  label='Name'
                  name='name'
                  rules={[
                    { required: true, message: "Please input your name!" },
                  ]}
                >
                  <Input placeholder='Your Name' />
                </Form.Item>
                <Form.Item
                  name='design'
                  labelCol={{ span: 24 }}
                  label='Card Design'
                  rules={[
                    { required: true, message: "Please select a card design!" },
                  ]}
                >
                  <Radio.Group>
                    <Radio value='1' style={{ marginTop: 10 }}>
                      <Image width={100} src={One} />
                    </Radio>
                    <Radio value='2' style={{ marginTop: 10 }}>
                      <Image width={100} src={Two} />
                    </Radio>
                    <Radio value='3' style={{ marginTop: 10 }}>
                      <Image width={200} src={Three} />
                    </Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item>
                  <Button
                    loading={loading}
                    disabled={loading}
                    type='primary'
                    htmlType='submit'
                  >
                    Generate Card
                  </Button>
                </Form.Item>
              </Form>
            </Card>
          </Col>
        </Row>
        <Text
          type='secondary'
          style={{
            marginBottom: 10,
            marginTop: 40,
          }}
        >
          Made by @rushd.com {new Date().getFullYear()}
        </Text>
      </div>
    </ConfigProvider>
  );
}

export default App;
